import { Box, Grid, Hidden, Typography } from '@mui/material';
import { Image } from 'common/components/Image';

export interface CardGridProps {
  title: string;
  description: string;
  items: CardGridItemProps[];
}

const CardGrid = ({ title, description, items }: CardGridProps) => {
  return (
    <Box
      width={'100%'}
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'center'}
    >
      <Box width={1120} maxWidth={'100%'}>
        <Grid container={true} spacing={10}>
          <Grid item={true} xs={12}>
            <Box textAlign={'center'}>
              <Hidden mdDown={true}>
                <Typography variant={'h2'}>{title}</Typography>
              </Hidden>
              <Hidden smUp={true}>
                <Typography variant={'h3'}>{title}</Typography>
              </Hidden>
              <Box marginTop={4} marginBottom={5}>
                <Typography variant={'body1'}>{description}</Typography>
              </Box>
            </Box>
          </Grid>
          {items.map((props, i) => {
            return (
              <CardGridItem
                key={i}
                {...props}
                md={Math.floor(12 / items.length) as 12}
              />
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

CardGrid.strapiComponent = 'maastr.card-grid';

export interface CardGridItemProps {
  image: string;
  title: string;
  description: string;
  md: number;
}

const CardGridItem = ({ md, image, title, description }: CardGridItemProps) => {
  return (
    <Grid item={true} xs={12} md={md as 12}>
      <Image
        alt={title}
        src={image || '/test-images/ui-example.png'}
        width={'100%'}
        height={'61.25%'}
        layout={'responsive'}
      />
      <Box marginTop={3} marginBottom={1}>
        <Typography variant={'h3'}>{title}</Typography>
      </Box>
      <Typography variant={'body1'}>{description}</Typography>
    </Grid>
  );
};

CardGridItem.strapiComponent = 'maastr.card-grid-item';

export { CardGrid, CardGridItem };
